/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/03/2023.
 */
import React, { Suspense } from 'react'
import { Link, NavLink, Outlet, useParams } from 'react-router-dom'
import { Header } from './Header'
import { ReactComponent as Logo } from '../../assets/logo_2.svg'
import { HeaderBlock } from './HeaderBlock'
import { EXPORTS_PAGE, getRoutes } from '../../routes'
import { COLOR_PRIMARY, MANUAL_VERSION, VERSION } from '../../constants'
import { animated, useSpring } from '@react-spring/web'
import { useMe } from '../../hooks/useMe'
import { USER_TYPES } from '../../Types'
import { DownOutlined } from '@ant-design/icons'

const headerHeight = 70

const LogoComponent = () => {
  return (
    <div className="w-100 h-100 position-relative mt-4">
      <Logo className="ms-3"></Logo>
      <p className="position-absolute p-small text-black opacity-50" style={{ top: 20, left: 63, fontSize: '0.7rem' }}>
        Version: {MANUAL_VERSION}
      </p>
    </div>
  )
}

export const Layout = () => {
  const { projectId } = useParams() as { projectId: string; siteId: string }

  return (
    <div className="container-fluid h-100">
      <div className="position-fixed bottom-0 noselect">
        <p className="mb-2 p-small text-black opacity-75" style={{ fontSize: '0.7rem' }}>
          Build: {VERSION}
        </p>
      </div>

      <div className="row flex-nowrap min-vh-100">
        {projectId ? (
          <div
            className="col-1 bg-white px-0 min-vh-100"
            style={{
              width: 200,
              borderRight: '1px solid rgba(0,0,0,.1)',
              boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.04)',
            }}
          >
            <Link to="/" className="border-bottom d-flex align-items-center" style={{ height: headerHeight }}>
              <LogoComponent />
            </Link>
            <div className="mt-3 d-flex flex-column align-items-center align-items-sm-start flex-grow-1  ">
              <Suspense fallback="...">
                <NavMenu></NavMenu>
              </Suspense>
            </div>
          </div>
        ) : (
          <div
            className="col-1 bg-white px-0"
            style={{
              height: headerHeight,
              width: 175,
              borderRight: '1px solid rgba(0,0,0,.1)',
              boxShadow: '2px 0px 10px rgba(0, 0, 0, 0.04)',
            }}
          >
            <Link
              to="/"
              className="border-bottom d-flex align-items-center text-decoration-none"
              style={{ height: headerHeight }}
            >
              <LogoComponent />
            </Link>
          </div>
        )}

        <div className="col px-0 d-flex flex-column flex-grow-1 ">
          <Header headerHeight={headerHeight}></Header>
          <HeaderBlock></HeaderBlock>

          <Outlet />
        </div>
      </div>
    </div>
  )
}

const NavMenu = () => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }
  const { data: me } = useMe(projectId)

  const type = me?.type
  const routes = getRoutes(type || '')

  return (
    <>
      {type !== USER_TYPES.CLINICIAN ? (
        <div
          className="p-small mb-2 mt-2 ms-3 d-flex text-black w-75 align-items-center "
          style={{ color: '#5d5d5d', fontSize: '.7rem' }}
        >
          Pages
        </div>
      ) : null}

      {projectId && type && type !== USER_TYPES.CLINICIAN
        ? routes.map((item, index) => {
            const path = type === USER_TYPES.CLINICIAN ? item.gotoPage(projectId, siteId) : item.gotoPage(projectId)

            return (
              <NavLink key={index} to={path} end={index === 0 ? true : false} className="text-decoration-none w-100">
                {({ isActive }) => {
                  return <NavItem active={isActive} type={USER_TYPES.TRIAL_STAFF} item={item}></NavItem>
                }}
              </NavLink>
            )
          })
        : null}

      {type === USER_TYPES.CLINICIAN && me.sites && me.sites.length > 0 ? (
        <>
          <div
            className="p-small text-muted mt-2 mb-2 ms-4 d-flex text-black w-75 align-items-center "
            style={{ color: '#5d5d5d', fontSize: '.8rem' }}
          >
            Sites
          </div>

          {me.sites.map((site, siteIndex) => {
            const isCurrentActiveSite = site._id === siteId

            return (
              <NestedNavMenu
                key={siteIndex}
                isCurrent={isCurrentActiveSite}
                projectId={projectId}
                routes={routes}
                site={site}
              ></NestedNavMenu>
            )
          })}

          <div
            className="p-small text-muted mt-2 mb-2 ms-4 d-flex text-black w-75 align-items-center "
            style={{ color: '#5d5d5d', fontSize: '.8rem' }}
          >
            Pages
          </div>

          {projectId && type
            ? [EXPORTS_PAGE].map((item, index) => {
                const path = item.gotoPage(projectId)

                return (
                  <NavLink
                    key={index}
                    to={path}
                    end={index === 0 ? true : false}
                    className="text-decoration-none w-100"
                  >
                    {({ isActive }) => {
                      // Left it as TRIALSTAFF for the pad setting in NavItem
                      return <NavItem active={isActive} type={USER_TYPES.TRIAL_STAFF} item={item}></NavItem>
                    }}
                  </NavLink>
                )
              })
            : null}
        </>
      ) : null}
    </>
  )
}

const NestedNavMenu = ({ isCurrent, routes, projectId, site }) => {
  const aniPropsOn = useSpring({
    opacity: isCurrent ? 1 : 0.5,
    height: isCurrent ? 150 : 0,
    //transform: active ? `translate3d(0px,${0}px,0)` : `translate3d(0px,${-20}px,0)`,
    config: { tension: 450, friction: 42 },
  })

  return (
    <>
      <NavLink to={`/project/${projectId}/site/${site._id}`} className="text-decoration-none w-100">
        {({ isActive }) => {
          return <NavItem active={isActive} type={USER_TYPES.CLINICIAN} item={{ title: site.title }}></NavItem>
        }}
      </NavLink>
      <animated.div className="w-100 overflow-hidden" style={{ marginBottom: isCurrent ? 10 : 0, ...aniPropsOn }}>
        {routes.map((item, subindex) => {
          const path = item.gotoPage(projectId, site._id)
          return (
            <NavLink
              key={subindex}
              to={path}
              end={subindex === 0 ? true : false}
              className="text-decoration-none w-100 "
            >
              {({ isActive }) => {
                return <NavItem active={isActive} type={USER_TYPES.CLINICIAN} pad={10} item={item}></NavItem>
              }}
            </NavLink>
          )
        })}
      </animated.div>
    </>
  )
}

const NavItem = ({ item, active, pad = 0, type }) => {
  const blockWidth = 4

  const isClinic = pad === 0 && type === USER_TYPES.CLINICIAN

  const activeStyles = {
    opacity: 0,
    color: COLOR_PRIMARY,
    transform: `translate3d(0px,${-10}px,0) scaleY(1)`,
    config: { tension: 500, friction: 30, mass: 0.5, delay: 0 },
  }
  const inActiveStyles = {
    opacity: 0.8,
    //color: 'rgba(0,0,0,.5)',
    color: 'rgba(0,0,0,.5)',
    transform: `translate3d(0px,${-25}px,0) scaleY(1)`,
    config: { tension: 350, friction: 32 },
  }

  const aniProps = useSpring(active ? activeStyles : inActiveStyles)

  const activeTextColor = type === USER_TYPES.CLINICIAN ? 'rgba(0,0,0,.8)' : COLOR_PRIMARY
  const aniPropsOn = useSpring({
    opacity: active ? 1 : 0,
    color: isClinic ? activeTextColor : COLOR_PRIMARY,
    transform: active ? `translate3d(0px,${8}px,0)` : `translate3d(0px,${-10}px,0)`,
    config: { tension: 600, friction: 30, mass: 0.5, delay: 500 },
  })

  const activeBlockColor = type === USER_TYPES.CLINICIAN && pad === 0 ? 'rgba(0,0,0,0)' : COLOR_PRIMARY
  const blockAniProps = useSpring({
    //opacity: pad !== 0 ? 1 : 0,
    background: active ? activeBlockColor : 'rgba(0,0,0,.0)',
    transform: active ? `translate3d(${0}px,0,0)` : `translate3d(${-2}px,0,0)`,
  })

  const filedWidth = pad === 0 ? 165 : 155
  const pwidth = isClinic ? filedWidth - 12 : filedWidth

  return (
    <div className="w-100 position-relative " style={{ top: 0, paddingTop: 1, paddingBottom: 1 }}>
      <animated.p
        className="position-absolute fw-bold h-100 "
        style={{ top: 0, width: blockWidth, ...blockAniProps }}
      ></animated.p>

      <div className=" " style={{ height: 40 }}>
        <animated.div className="ms-4 my-2 fw-bold text-truncate  " style={{ paddingLeft: pad, ...aniPropsOn }}>
          <p className="text-truncate" style={{ fontSize: '.9rem', width: pwidth }}>
            {item.title}
          </p>
          {isClinic ? <DownOutlined className="position-absolute" style={{ top: 5, right: 9, opacity: 0.55 }} /> : null}
        </animated.div>

        <animated.div
          className="ms-4 my-2 fw-bold  w-100 position-relative"
          style={{ width: filedWidth, paddingLeft: pad, ...aniProps }}
        >
          <p className="text-truncate" style={{ fontSize: '.9rem', width: pwidth }}>
            {item.title}
          </p>
          {isClinic ? (
            <DownOutlined
              className="position-absolute"
              style={{ top: 5, right: 33, opacity: 0.4, transform: 'rotate(-90deg)' }}
            />
          ) : null}
        </animated.div>
      </div>
    </div>
  )
}
