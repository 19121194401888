/**
 * Created by @author @ddennis - ddennis.dk aka fantastisk.dk/works aka meresukker.dk on 13/03/2023.
 */
import { Button, Empty } from 'antd'
import React, { Suspense } from 'react'
import { PageHeader } from '../../components/display/PageHeader'
import { Link, Route, Routes, useParams } from 'react-router-dom'
import { SpinnerFullHeight } from '../../components/spinner/SpinnerFullHeight'
import { useFadeIn } from '../../components/animation/useFadeIn'
import { DrawerContainer } from '../../components/drawer/DrawerContainer'
import { TrialStaffCRUD } from './TrialStaffCRUD'
import { ClinicianCRUD } from './ClinicianCRUD'
import { CRUD_VIEW_TYPE, SiteType, USER_TYPES } from '../../Types'
import { useStaffForSite } from '../../hooks/useStaffForSite'
import { TrialStaffListRender } from './TrialStaffListRender'
import { ActionActivity } from './ActionActivity'
import { useMe } from '../../hooks/useMe'

const CREATE_CLINICIAN = 'create-clinician'
//const CREATE_TRAIL_STAFF = 'create-staff'

export const TrailStaffClinicianPage = () => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }

  const { data: me } = useMe(projectId)

  const { data } = useStaffForSite(projectId, siteId)

  const site = data[0] as SiteType
  const clinicians = data[1]
  const cras = data[2]
  const dms = data[3]
  const ctms = data[4]

  const { animated, spring } = useFadeIn()

  const closeDrawer = (value) => {
    // Implement if needed to update page (with mutate)
    // if (!value) {
    //   mutate()
    // }
  }

  return (
    <>
      <Routes>
        <Route
          path={'/create-staff'}
          element={
            <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
              <DrawerContainer projectId={projectId} setVisible={closeDrawer} to="../" title="Create new trial staff">
                <TrialStaffCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.CREATE} me={me}></TrialStaffCRUD>
              </DrawerContainer>
            </Suspense>
          }
        />

        <Route
          path="/view-staff/:userId/*"
          element={
            <DrawerContainer
              projectId={projectId}
              setVisible={closeDrawer}
              to="../"
              title="Trial staff information"
              header={
                <div className="">
                  <Link to={'logins'} className="">
                    <Button type="default" className="me-2">
                      Logins
                    </Button>
                  </Link>
                  <Link to={'actions'} className="">
                    <Button type="default">Actions</Button>
                  </Link>
                </div>
              }
            >
              <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                <TrialStaffCRUD closeDrawer={closeDrawer} viewType={CRUD_VIEW_TYPE.VIEW} me={me}></TrialStaffCRUD>
                <Routes>
                  <Route
                    path="/edit"
                    element={
                      <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                        <DrawerContainer
                          projectId={projectId}
                          width="30%"
                          to="../"
                          title="Edit trial staff information"
                        >
                          <TrialStaffCRUD
                            closeDrawer={closeDrawer}
                            viewType={CRUD_VIEW_TYPE.UPDATE}
                            me={me}
                          ></TrialStaffCRUD>
                        </DrawerContainer>
                      </Suspense>
                    }
                  />
                  {/* <Route
                    path="/logins"
                    element={
                      <DrawerContainer projectId={''} to="../" title="Logins">
                        <LoginActivity type={USER_TYPES.TRIAL_STAFF}></LoginActivity>
                      </DrawerContainer>
                    }
                  /> */}
                  <Route
                    path="/actions"
                    element={
                      <DrawerContainer projectId={''} to="../" title="Actions">
                        <ActionActivity type={USER_TYPES.TRIAL_STAFF}></ActionActivity>
                      </DrawerContainer>
                    }
                  />
                </Routes>
              </Suspense>
            </DrawerContainer>
          }
        />

        <Route
          path={'/clinician/:userId/*'}
          element={
            <>
              <DrawerContainer
                projectId={''}
                to="../"
                title="View clinician"
                header={
                  <div className="">
                    <Link to={'logins'} className="">
                      <Button type="default" className="me-2">
                        Logins
                      </Button>
                    </Link>
                    <Link to={'actions'} className="">
                      <Button type="default" className="me-2">
                        Actions
                      </Button>
                    </Link>
                  </div>
                }
              >
                <Suspense fallback={<SpinnerFullHeight></SpinnerFullHeight>}>
                  {/*
                  // this way the onclose is not passed to the ClinicianCRUD
                  // meaning the close button will not work
                  */}
                  <ClinicianCRUD viewType={CRUD_VIEW_TYPE.VIEW} me={me}></ClinicianCRUD>

                  <Routes>
                    <Route
                      path="/edit"
                      element={
                        <DrawerContainer projectId={''} to="../" title="Edit clinician">
                          <ClinicianCRUD viewType={CRUD_VIEW_TYPE.UPDATE} me={me}></ClinicianCRUD>
                        </DrawerContainer>
                      }
                    />
                    {/* <Route
                      path="/logins"
                      element={
                        <DrawerContainer projectId={''} to="../" title="Logins">
                          <LoginActivity type={USER_TYPES.CLINICIAN}></LoginActivity>
                        </DrawerContainer>
                      }
                    /> */}
                    <Route
                      path="/actions"
                      element={
                        <DrawerContainer projectId={''} to="../" title="Actions">
                          <ActionActivity type={USER_TYPES.CLINICIAN}></ActionActivity>
                        </DrawerContainer>
                      }
                    />
                  </Routes>
                </Suspense>
              </DrawerContainer>
            </>
          }
        />

        <Route
          path={'/' + CREATE_CLINICIAN}
          element={
            <DrawerContainer projectId={''} to="../" title="Create clinician">
              <ClinicianCRUD viewType={CRUD_VIEW_TYPE.CREATE} me={me}></ClinicianCRUD>
            </DrawerContainer>
          }
        />
      </Routes>

      <animated.div style={spring} className="  d-flex flex-column flex-fill">
        <div
          className="container-fluid bg-white"
          style={{
            borderBottom: '1px solid rgba(0,0,0,.1)',
            boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.04)',
          }}
        >
          <div className="container px-lg-6 mb-3" style={{}}>
            <PageHeader
              title={`${site.title} - Trial Staff`}
              body="Get a complete overview of the Trial Staff participating in the study.
              Explore individual profiles to view titles, roles, and other information. Review the audit log and logins for a detailed history of system activities, ensuring transparency and security."
            ></PageHeader>
          </div>
        </div>

        <div className="container px-lg-6 mb-7">
          {ctms?.length > 0 ? (
            <div className="row" style={{}}>
              <p className="p-small my-3 fw-bold  ">CTM's </p>

              <TrialStaffListRender linkPath="" data={ctms} />
            </div>
          ) : null}

          {clinicians?.length > 0 ? (
            <div className="row" style={{}}>
              <p className="p-small my-4 fw-bold ">Clinicians</p>

              <TrialStaffListRender linkPath="clinician/" data={clinicians} />
            </div>
          ) : null}

          {cras?.length > 0 ? (
            <div className="row" style={{}}>
              <p className="p-small my-4 fw-bold ">CRA's</p>

              <TrialStaffListRender linkPath="view-staff/" data={cras} />
            </div>
          ) : null}

          {dms?.length > 0 ? (
            <div className="row" style={{}}>
              <p className="p-small my-4 fw-bold ">DM's</p>

              <TrialStaffListRender linkPath="view-staff/" data={dms} />
            </div>
          ) : null}

          {dms?.length === 0 && clinicians?.length === 0 && ctms?.length === 0 && cras?.length === 0 ? (
            <div className="row" style={{}}>
              <Empty description="No trail staff user has been added at this time"></Empty>
            </div>
          ) : null}
        </div>
      </animated.div>
    </>
  )
}
