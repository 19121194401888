import { StarOutlined } from '@ant-design/icons'
import { Button, Modal, Popconfirm } from 'antd'
import { SetStateAction, useState } from 'react'

import { mutate as mutateGlobal } from 'swr'
import { post } from '../../../service/API'
import { ENDPOINTS } from '../../../service/ENDPOINTS'
import { useParams } from 'react-router-dom'

type CompleteModalProps = {
  isModalOpen: boolean
  setIsReQualifyModalOpen: (e: SetStateAction<boolean>) => void
  subjectId: string
  mutateSubjectData: any
}

export const CompleteModal = ({
  isModalOpen,
  setIsReQualifyModalOpen,
  subjectId,
  mutateSubjectData,
}: CompleteModalProps) => {
  const { projectId, siteId } = useParams() as { projectId: string; siteId: string }

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [openPop, setOpenPop] = useState(false)

  const showPopconfirm = () => {
    setOpenPop(true)
  }

  const confirm = () => {
    setConfirmLoading(true)

    const obj = { projectId: projectId, subjectId: subjectId }

    return post(ENDPOINTS.SUBJECTS.ACTION.complete(projectId), obj, 'PUT')
      .then(() => {
        setTimeout(() => {
          mutateSubjectData()
          setIsReQualifyModalOpen(false)
          setConfirmLoading(false)
          setOpenPop(false)
          mutateGlobal(ENDPOINTS.SUBJECTS.getSubjectAudit(projectId, subjectId))
          mutateGlobal(ENDPOINTS.SUBJECTS.get(projectId, siteId))
        }, 1000)
      })
      .catch((error) => {
        alert(error.message)
      })
  }

  const hideModal = () => {
    setIsReQualifyModalOpen(false)
    setOpenPop(false)
  }

  const handleCompleteOk = () => {
    setIsReQualifyModalOpen(false)
    setOpenPop(false)
  }

  const handleCompleteCancel = () => {
    setIsReQualifyModalOpen(false)
    setOpenPop(false)
  }

  const cancel = () => {
    setIsReQualifyModalOpen(false)
    setOpenPop(false)
  }

  return (
    <>
      <Modal
        title={[
          <div key={1}>
            <StarOutlined className="me-2" style={{ color: 'green', fontSize: '22px' }} />
            Study completion for subject
          </div>,
        ]}
        open={isModalOpen}
        onOk={handleCompleteOk}
        onCancel={handleCompleteCancel}
        destroyOnClose={true}
        width={600}
        footer={[
          <div key={2}>
            <Button onClick={hideModal}>Cancel</Button>
            <Popconfirm
              title="Confirmation: Complete the study participation for the subject?"
              onConfirm={confirm}
              open={openPop}
              onCancel={cancel}
              okButtonProps={{ loading: confirmLoading }}
              okText="Confirm"
              cancelText="Cancel"
            >
              <Button className="ms-2" type="primary" style={{ background: 'green' }} onClick={showPopconfirm}>
                Complete the Study
              </Button>
            </Popconfirm>
          </div>,
        ]}
      >
        <p className="mt-2">
          You are about to complete the study participation for the subject. Please check all details before confirming
          completion. The subject's device will be disabled, please ensure the collection and wiping of the devices.
        </p>
      </Modal>
    </>
  )
}
